import { InlineWidget } from 'react-calendly';
import { useState } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import Config from '../../../config/Config';
import OnboardingCard from '../OnboardingCard';
import Modal from '../../Modal';
import Button from '../../Button';

const staticContent = {
  title: 'Book a 1-on-1',
  description:
    'Meet with a SoleSavy expert 1-on-1. Whether you need an introduction to the community or a lesson in copping sneakers, we’ve got you covered.',
};
const OneOnOneComponent = () => {
  const [openCalendlyModal, setOpenCalendlyModal] = useState(false);
  const handleOpenCalendlyModal = () => setOpenCalendlyModal(true);
  const closeCalendlyModal = () => {
    setOpenCalendlyModal(false);
  };
  const isMobile = useMediaQuery('(max-width: 768px)');
  const leftPanel = 'one-on-one';
  const rightIcon = (
    <div
      className={`flex rounded-custom bg-dodgerBlue justify-center items-center ${
        isMobile ? 'w-12 h-12' : 'w-20 h-20'
      }`}
    >
      <embed
        src="/icons/video.svg"
        width={isMobile ? '40' : '60'}
        height={isMobile ? '40' : '60'}
        tabIndex={-1}
      />
    </div>
  );

  const rightButton = (
    <Button
      btnContext="Book Now"
      canActivate
      textSize="text-sm"
      handleClick={handleOpenCalendlyModal}
    />
  );
  const content = {
    ...staticContent,
    leftPanel,
    rightIcon,
    rightButtons: [rightButton],
  };

  return (
    <>
      <OnboardingCard onboardingCardContent={content} />
      <Modal openModal={openCalendlyModal} handleClose={closeCalendlyModal} overrideInternalModal>
        <InlineWidget
          styles={{
            width: '90vw',
            maxWidth: '650px',
            height: isMobile ? '75vh' : '90vh',
            borderRadius: '.75rem',
          }}
          url={Config.CALENDLY_TRAINING_URL}
        />
      </Modal>
    </>
  );
};
const OneOnOne = {
  component: OneOnOneComponent,
  staticContent,
};
export default OneOnOne;
