import Image from 'next/dist/client/image';
import { ReactElement, Fragment, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Config from '../config/Config';
import Modal from './Modal';
import DropsApp from './Onboarding/Steps/DropsApp';
import CollectApp from './Onboarding/Steps/CollectApp';
import { ROLES, useAuth } from '../providers/Auth';
import { isTesting } from '../utils';
import styles from '../styles/Home.module.css';

export default function AppLaunchSection() {
  const [openAppModal, setOpenAppModal] = useState(false);
  const [openCollectModal, setOpenCollectModal] = useState(false);
  const [appDownloadUrl, setAppDownloadUrl] = useState('');
  const handleOpenAppModal = () => setOpenAppModal(true);
  const handleOpenCollectModal = () => setOpenCollectModal(true);
  const { hasRole } = useAuth();

  const closeAppModal = () => {
    setOpenAppModal(false);
  };
  const closeCollectModal = () => {
    setOpenCollectModal(false);
  };
  const open = (appNameUpper: string) => {
    if (appNameUpper === 'DROPS' && !isMobile) {
      handleOpenAppModal();
      return;
    }
    if (appNameUpper === 'COLLECT' && !isMobile) {
      handleOpenCollectModal();
      return;
    }

    if (isMobile) {
      window.location.replace(Config[`OPEN_${appNameUpper}_DEEP_LINK`]);
    } else {
      window.open(Config[`LAUNCH_${appNameUpper}_URL`], '_blank');
    }
  };

  const download = (appNameUpper: string) => {
    if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
      setAppDownloadUrl(Config[`${appNameUpper}_ANDROID_APP`]);
    } else if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
      setAppDownloadUrl(Config[`${appNameUpper}_APPLE_APP`]);
    }
    if (appDownloadUrl) {
      window.location.replace(appDownloadUrl);
    }
  };

  const openOrDownload = (appName: string) => {
    const appNameUpper = appName.toUpperCase();

    setTimeout(() => {
      if (
        appNameUpper === 'SLACK' ||
        appNameUpper === 'DROPS' ||
        appNameUpper === 'COLLECT' ||
        isMobile
      ) {
        download(appNameUpper);
      }
    }, 2000);

    open(appNameUpper);
  };

  return (
    <>
      <div className={`${styles.buttons} flex items-center gap-4`}>
        {(hasRole(ROLES.slack) || isTesting()) && (
          <button
            data-testid="slackButton"
            key="Slack"
            onClick={() => openOrDownload('slack')}
            type="button"
            className={`${styles.appButton} flex items-center gap-2 appearance-none cursor-pointer text-sm uppercase bg-white shadow-sm hover:shadow-sm font-tommyBold text-gray-900 py-2 px-4 rounded-full transition duration-350 ease-in-out`}
          >
            <span className="h-6">
              <Image src="/icons/slack.svg" width={24} height={24} />
            </span>
            <span>Slack</span>
            <span className={`h-4 ${styles.hoverHide}`}>
              <Image src="/icons/launch.svg" width={14} height={14} />
            </span>
            <span className={`h-4 hidden ${styles.hoverShow}`}>
              <Image src="/icons/launch-white.svg" width={14} height={14} />
            </span>
          </button>
        )}
        {(hasRole(ROLES.collect) || isTesting()) && (
          <button
            data-testid="collectButton"
            key="Collect"
            onClick={() => openOrDownload('collect')}
            type="button"
            className={`${styles.appButton} flex items-center gap-2 appearance-none cursor-pointer text-sm uppercase bg-white shadow-sm hover:shadow-sm font-tommyBold text-gray-900 py-2 px-4 rounded-full transition duration-350 ease-in-out`}
          >
            <span className="h-6">
              <Image src="/icons/collect.svg" width={24} height={24} />
            </span>
            <span>Collect</span>
            <span className={`h-4 ${styles.hoverHide}`}>
              <Image src="/icons/launch.svg" width={14} height={14} />
            </span>
            <span className={`h-4 hidden ${styles.hoverShow}`}>
              <Image src="/icons/launch-white.svg" width={14} height={14} />
            </span>
          </button>
        )}
        {(hasRole(ROLES.assist) || isTesting()) && (
          <button
            data-testid="assistButton"
            key="Assist"
            onClick={() => openOrDownload('assist')}
            type="button"
            className={`${styles.appButton} flex items-center gap-2 appearance-none cursor-pointer text-sm uppercase bg-white shadow-sm hover:shadow-sm font-tommyBold text-gray-900 py-2 px-4 rounded-full transition duration-350 ease-in-out`}
          >
            <span className="h-6">
              <Image src="/icons/assist.svg" width={24} height={24} />
            </span>
            <span>Assist</span>
            <span className={`h-4 ${styles.hoverHide}`}>
              <Image src="/icons/launch.svg" width={14} height={14} />
            </span>
            <span className={`h-4 hidden ${styles.hoverShow}`}>
              <Image src="/icons/launch-white.svg" width={14} height={14} />
            </span>
          </button>
        )}
        {(hasRole(ROLES.drops) || isTesting()) && (
          <button
            data-testid="dropsButton"
            key="Drops app"
            onClick={() => openOrDownload('drops')}
            type="button"
            className={`${styles.appButton} flex items-center gap-2 appearance-none cursor-pointer text-sm uppercase bg-white shadow-sm hover:shadow-sm font-tommyBold text-gray-900 py-2 px-4 rounded-full transition duration-350 ease-in-out`}
          >
            <span className="h-6">
              <Image src="/icons/drops-button.svg" width={24} height={24} />
            </span>
            <span>Drops</span>
            <span className={`h-4 ${styles.hoverHide}`}>
              <Image src="/icons/launch.svg" width={14} height={14} />
            </span>
            <span className={`h-4 hidden ${styles.hoverShow}`}>
              <Image src="/icons/launch-white.svg" width={14} height={14} />
            </span>
          </button>
        )}
      </div>
      {!!appDownloadUrl && (
        <iframe title="appStore" src={appDownloadUrl} height={1} width={1} className="invisible" />
      )}
      <Modal openModal={openAppModal} handleClose={closeAppModal}>
        <div className="w-96 flex flex-col justify-center text-center">
          <Image src="/icons/qr-mobile-app.svg" width={116} height={116} />
          <h2 className="font-tommyBold text-3xl font-medium mb-2 pt-6 pb-2">Launch DROPS</h2>
          <p className="text-gray-700 mt-2 mb-3">{DropsApp.staticContent.description}</p>

          <div className="pt-2 flex justify-center">
            {DropsApp.staticContent.rightButtons.map((rightButton: ReactElement, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={i}>{rightButton}</Fragment>
            ))}
          </div>
        </div>
      </Modal>
      <Modal openModal={openCollectModal} handleClose={closeCollectModal}>
        <div className=" w-96 flex flex-col justify-center text-center">
          <Image src="/images/qr-collect-app.svg" width={116} height={116} />
          <h2 className="font-tommyBold text-3xl font-medium mb-2 pt-6 pb-2">Launch COLLECT</h2>
          <p className="text-gray-700 mt-2 mb-3">{CollectApp.staticContent.description}</p>
          <div className="pt-2 flex justify-center">
            {CollectApp.staticContent.rightButtons.map((rightButton: ReactElement, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={i}>{rightButton}</Fragment>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
}
