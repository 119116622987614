import { useMediaQuery } from 'beautiful-react-hooks';
import OnboardingCard from '../OnboardingCard';
import Button from '../../Button';

const staticContent = {
  title: 'Access the Slack Community',
  description:
    'Use your SoleSavy credentials to log in and access the Slack community from all your devices.',
};
const SlackInvitationComponent = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const leftPanel = 'slack-invitation';
  const rightIcon = (
    <div
      className={`flex rounded-custom bg-slackPurple justify-center items-center ${
        isMobile ? 'w-12 h-12' : 'w-20 h-20'
      }`}
    >
      <embed
        src="/icons/slack-four-color.svg"
        width={isMobile ? '40' : '60'}
        height={isMobile ? '40' : '60'}
        tabIndex={-1}
      />
    </div>
  );

  const rightButton = (
    <Button
      btnContext="Open Slack"
      canActivate
      textSize="text-sm"
      target="_blank"
      href="https://solesavy.enterprise.slack.com/"
      rightIconName="chevron_right_white"
      iconSize={14}
    />
  );
  const content = {
    ...staticContent,
    leftPanel,
    rightIcon,
    rightButtons: [rightButton],
  };

  return (
    <>
      <OnboardingCard onboardingCardContent={content} />
    </>
  );
};
const SlackInvitation = {
  component: SlackInvitationComponent,
  staticContent,
};
export default SlackInvitation;
